<template>
  <div class="framework">
    <van-divider
      :style="{ color: '#464646', borderColor: '#464646', padding: '0 1rem' }"
    >
      支付宝，微信购买卡密
    </van-divider>
    <div>
      <van-button
        type="primary"
        size="small"
        style="margin-right: 10px"
        @click="onConfirm(5, 0)"
        >购买5元卡密</van-button
      >
      <van-button
        type="primary"
        size="small"
        style="margin-right: 10px"
        @click="onConfirm(10, 1)"
        >购买10元卡密</van-button
      >
      <van-button
        type="primary"
        size="small"
        style="margin-right: 10px"
        @click="onConfirm(50, 2)"
        >购买50元卡密</van-button
      >
      <van-button
        type="primary"
        size="small"
        style="margin-right: 10px"
        @click="onConfirm(100, 3)"
        >购买100元卡密</van-button
      >
    </div>
    <div v-if="this.$api.IsAgent != true">
      <van-divider
        :style="{ color: '#464646', borderColor: '#464646', padding: '0 1rem' }"
      >
        ⬇⬇使用USDT(TRC20)直接充值到余额⬇⬇
      </van-divider>
      <div v-if="show2">
        <van-field
          v-model="money"
          type="digit"
          label="输出充值金额"
          placeholder="输入5-1000的任意金额"
        />
      </div>
      <van-button type="info" @click="order_click"
        >点我使用USDT(只支持Trc20)充值</van-button
      >
    </div>
    <div>
      <van-list v-model="loading" :finished="finished">
        <van-cell
          v-for="item in list"
          :key="item.id"
          :title="item.title"
          v-clipboard:copy="item.url"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          @click="coppys(item.id)"
        >
        </van-cell>
      </van-list>
    </div>
    <div>
      <div>
        <van-divider
          :style="{
            color: '#464646',
            borderColor: '#464646',
            padding: '0 1rem',
          }"
        >
          购买卡密后在此激活
        </van-divider>
      </div>
      <div class="div">
        <van-field
          v-model="message"
          rows="2"
          autosize
          label="输入卡密"
          type="textarea"
          placeholder="在此输入购买的卡密"
          show-word-limit
        />
        <van-button type="info" @click="btn_click">点我激活卡密</van-button>
      </div>
    </div>
    <!--链接投诉-->
    <div v-if="this.$api.IsAgent != true">
      <div>
        <van-divider
          :style="{
            color: '#464646',
            borderColor: '#464646',
            padding: '0 1rem',
          }"
        >
          卡密不对或其他卡密问题在此投诉
        </van-divider>
      </div>
      <div>
        <van-form>
          <van-field
            v-model="link_url"
            autosize
            label="购卡链接(自动填写的如果不对请在发卡链接选择)"
            placeholder="购买卡密的链接"
            type="textarea"
            readonly
          />

          <van-field
            v-model="user_serch"
            autosize
            type="textarea"
            label="购买凭证"
            placeholder="购买时填写的联系方式或发卡网订单号"
          />
          <van-field
            v-model="user_note"
            type="textarea"
            label="投诉原因"
            autosize
            placeholder="投诉原因，如果发卡网购买有取卡密码请也在此备注,方便核实"
          />
          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              @click="commit_complan"
              >提交投诉</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
    <div v-if="this.$api.IsAgent != true">
      <div>
        <van-divider
          :style="{
            color: '#464646',
            borderColor: '#464646',
            padding: '0 1rem',
          }"
        >
          卡密投诉记录
        </van-divider>
      </div>
      <div>
        <table width="90%" class="table">
          <thead>
            <tr>
              <th>链接</th>
              <th>处理状态</th>
              <th>处理结果</th>
            </tr>
          </thead>
          <tr
            v-for="item in complanlist"
            :key="item.id"
            v-clipboard:copy="item.modle"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <td>
              {{ item.link_url }}
            </td>
            <td>{{ item.state }}</td>
            <td>{{ item.ret_note }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";

export default {
  data() {
    return {
      showPicker: false,
      columnsValue: ["5", "10", "50", "100"],
      value: "",
      index: 0,
      list: [],
      loading: false,
      finished: true,
      message: "",
      state: false,
      link_id: 0,
      link_url: "",
      user_serch: "", //用户凭证
      user_note: "", //用户投诉原因
      complanlist: [], //用户投诉列表
      show: false,
      Answer: "",
      money: "",
      show2: false,
    };
  },
  methods: {
    coppys(id) {
      this.link_id = id;
      localStorage.setItem("link_id", id);
    },
    onCopy(e) {
      localStorage.setItem("link_url", e.text);
      this.link_url = e.text;
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
    async commit_complan() {
      if (this.user_serch.length === 0 || this.user_serch.length === 0) {
        this.$toast("请把参数填写完整");
        return;
      }
      Dialog.confirm({
        title: "重要提示",
        message:
          "恶意投诉会扣钱或采取封号措施\n继续投诉请点确定,不投诉点击取消",
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
          return;
        });

      let ret = await this.$api.fn_link_complaints_push(
        this.link_id,
        this.user_serch,
        this.user_note
      );
      ret = ret.data;
      if (ret.message === "ok") {
        this.complanlist.push({
          id: this.link_id,
          state: "未处理",
          ret_note: "",
          link_url: this.link_url,
        });
        this.$toast("投诉成功");
      } else {
        this.$toast(ret.message);
      }
      this.user_serch = "";
      this.user_note = "";
    },
    async onConfirm(value, index) {
      this.list = [];
      this.value = value;
      this.showPicker = false;
      this.index = index;

      if (this.$api.IsAgent == true) {
        //中介URL
        if (localStorage.getItem("agent_url") == "null") {
          //使用官方的
          let ret = await this.$api.fn_buylink_get(value);
          ret = ret.data;
          if (typeof ret == "string") {
            ret = ret.replace("\\/g", "");
            ret = eval("(" + ret + ")");
          }
          ret = ret.data;
          ret.forEach((element) => {
            this.list.push({
              title: "[链接ID:" + element.id + "]     " + element.url,
              id: element.id,
              url: element.url,
            });
          });
        }else if(localStorage.getItem("agent_url") == ""){
          //自行联系
          this.list.push({
          title: "[链接ID:" + "1" + "] " + "联系客服进行购买卡密",
          id: "1",
          url: "联系客服进行购买卡密",
        });
        }else{
          this.list.push({
          title: "[链接ID:" + "1" + "] " + localStorage.getItem("agent_url"),
          id: "1",
          url: localStorage.getItem("agent_url"),
        });
        }

      } else {
        let ret = await this.$api.fn_buylink_get(value);
        ret = ret.data;
        if (typeof ret == "string") {
          ret = ret.replace("\\/g", "");
          ret = eval("(" + ret + ")");
        }
        ret = ret.data;
        ret.forEach((element) => {
          this.list.push({
            title: "[链接ID:" + element.id + "]     " + element.url,
            id: element.id,
            url: element.url,
          });
        });
      }

      this.loading = true;
      this.finished = false;
      this.finished = true;
    },
    async order_click() {
      if (!this.show2) {
        this.show2 = true;
        return;
      }
      // 创建订单
      if (this.money < 5) {
        this.$toast("充值金额需要大于5元");
        return;
      }
      let ret = await this.$api.fn_order_creat(this.money);
      if (!ret) {
        this.$toast("请求错误");
        return;
      }
      ret = ret.data;
      if (ret.message != "ok") {
        this.$toast(ret.message);
        return;
      }
      window.location.href = ret.pay_url;
    },
    async btn_click() {
      if (this.message.length === 0) {
        this.$dialog.alert({ message: "卡密都不输入,你小子是在玩我？" });
        return;
      }
      if (this.state) {
        return;
      }
      this.state = true;
      let key = this.message;
      key = key.replace(/\r\n/g, "");
      key = key.replace(/\n/g, "");
      let ret = "";
      if (typeof this.Answer === "undefined") {
        ret = await this.$api.fn_key_push(key, "");
      } else {
        ret = await this.$api.fn_key_push(key, this.Answer);
      }
      let c = ret.data;
      if (c.message === "token失效,请重新登录") {
        localStorage.clear();
        this.$toast("退出登录");
        this.$router.replace({ path: "/login" });
        return;
      }
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("充值成功");
      } else if (ret.message === "安全码是弱密码,请修改安全码后再使用卡密") {
        Dialog.alert({
          message:
            "因安全码太弱，容易被盗，点击确定按钮后将设置新的安全码来激活卡密",
        })
          .then(() => {
            // on confirm
            this.show = true;
          })
          .catch(() => {
            // on cancel
            return;
          });
      } else {
        //this.show = true;
        this.$toast(ret.message + "\r\n有疑问请联系客服帮忙查询");
      }
      this.state = false;
    },
  },
  async created() {
    this.link_id = localStorage.getItem("link_id");
    this.link_url = localStorage.getItem("link_url");
    Dialog.alert({
      message:
        "如果卡密不能充值或错误请联系链接所属卡商解决，或联系客服投诉进行加款。\n购买卡密时联系方式禁止填写过于简单【如：123456等等】，已经有不少人因此被盗卡密。",
    }).then(() => {
      // on close
    });
    let ret = await this.$api.fn_link_complaints_get();
    ret = ret.data;
    if (typeof ret == "string") {
      ret = ret.replace("\\/g", "");
      ret = eval("(" + ret + ")");
    }
    ret = ret.data;
    this.complanlist = [];
    ret.forEach((element) => {
      let state = "";
      if (element.state === "1") {
        state = "已处理";
      } else {
        state = "未处理";
      }
      this.complanlist.push({
        id: element.id,
        state: state,
        ret_note: element.ret_note,
        link_url: element.link_url,
      });
    });
  },
};
</script>

<style scoped>
.div {
  margin-left: 1rem;
  margin-right: 1rem;
  border: 1px solid #464646;
}
.framework {
  text-align: center;
  margin-top: 1rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  margin-bottom: 0rem;
  position: relative;
  overflow: hidden;
  zoom: 100%;
}
table {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 10px;
  margin-bottom: 5rem;
  table-layout: fixed;
}
table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 30px;
  word-wrap: break-word;
}
table thead th {
  background-color: #cce8eb;
  width: 100px;
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #cbf3f3;
}
</style>